
import { Component, Vue } from 'vue-property-decorator';
import { $enum } from 'ts-enum-util';

import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient, ICustomColumn, IForm,
} from '@/modules/reports/interfaces';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/reports/constants';

import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import ScheduledReportsPopup from '@/modules/reports';

import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import type ProvidersService from '@/modules/providers/providers.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';

import loop24 from '@/modules/common/filters/loop-24.filter';
import type ClusterService from '../cluster.service';
import ClusterRatesService from '../cluster-rates.service';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @inject(KEY.ClusterService) clusterService!: ClusterService;
    @inject(KEY.ClusterRatesService) private clusterRatesService!: ClusterRatesService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    form: IForm = {} as IForm;

    get properties(): IProperties {
        return {
            dataType: DATA_TYPE.RATES_CLUSTER,
            dateRange: {
                options: [30],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns(): ICustomColumn[] {
        return [{
            name: 'change',
            label: this.$tc('titles.change'),
            value: true,
        }, {
            name: 'score',
            label: this.$tc('titles.score'),
            value: true,
        }, {
            name: 'diff_delta',
            label: this.$tc('titles.diffnum'),
            value: true,
        }, {
            name: 'diff_precent',
            label: this.$tc('titles.diffper'),
            value: true,
        }];
    }

    get filters(): IFilterItem[] {
        const filters = [
            this.providersFilter,
            this.priceTypesFilter,
            this.occupancyFilter,
            this.roomTypesFilter,
            this.mealTypesFilter,
        ] as IFilterItem[];

        return filters;
    }

    get priceTypesFilter() {
        const { filters: values = {} } = this.form;

        const choosenPriceType = values.price_type
            || this.clusterRatesService.settings.priceType
            || this.settingsGeneralService.defaultFilters.price;

        return {
            name: 'price_type',
            label: this.$tc('titles.price'),
            value: choosenPriceType as PRICE_TYPE,
            options: $enum(PRICE_TYPE).map(value => ({
                name: this.$tc(`price.${value}`),
                value,
            })),
            disableOnEdit: false,
        };
    }

    get occupancyFilter() {
        const { filters: values = {} } = this.form;

        const choosenOccupancy = values.nog
            || this.clusterRatesService.settings.numberOfGuests
            || this.settingsGeneralService.defaultFilters.numberOfGuests;

        return {
            name: 'nog',
            label: this.$tc('titles.numberOfGuests'),
            value: choosenOccupancy as number,
            options: Array
                .from({ length: 10 })
                .map((_, i) => i + 1)
                .map(e => ({
                    value: e,
                    name: this.$tc('filters.guests', e, [e]).toString(),
                })),
            disableOnEdit: false,
        };
    }

    get roomTypeItems() {
        const { roomsWithAny } = this.roomTypesService;
        const items = roomsWithAny.map((room: RoomTypeModel) => ({
            value: room.name,
            name: room.id !== -1
                ? this.$tc(room.name)
                : this.$tc(`filters.${room.name}`),
        }));

        return [...items];
    }

    get roomTypesFilter() {
        const { filters: values = {} } = this.form;
        const { roomTypeId } = this.clusterRatesService.settings;

        if (Array.isArray(values.room_type)) {
            [values.room_type] = values.room_type;
        }

        const defaultRoomTypeValues = roomTypeId === ANY_ROOM_TYPE.id
            ? ANY_ROOM_TYPE.name
            : this.roomTypesService.getRoomType(roomTypeId)!.name;

        const choosenRoomTypes = (values.room_type || defaultRoomTypeValues) as string;

        return {
            name: 'room_type',
            label: this.$tc('titles.roomType'),
            value: choosenRoomTypes,
            options: this.roomTypeItems,
            disableOnEdit: false,
        };
    }

    get mealTypesFilter() {
        const { filters: values = {} } = this.form;

        if (Array.isArray(values.meal_type)) {
            [values.meal_type] = values.meal_type;
        }

        const { mealTypeId } = this.clusterRatesService.settings;
        const { mealTypes } = this.mealTypesService;

        const defaultMealTypeValues = mealTypeId === ANY_MEAL_TYPE.id
            ? ANY_MEAL_TYPE.name
            : this.mealTypesService.getMealType(mealTypeId)!.name;

        const choosenMealTypes = (values.meal_type || defaultMealTypeValues) as string;

        return {
            name: 'meal_type',
            label: this.$tc('titles.mealType'),
            value: choosenMealTypes,
            options: mealTypes.map((mealType: MealTypeModel) => ({
                value: mealType.name,
                name: this.$tc(mealType.displayName),
            })),
            disableOnEdit: false,
        };
    }

    get providersFilter() {
        const { filters: values = {} } = this.form;
        const choosenProvider = (values.providers
            || this.clusterService.currentRatesProvider) as string;

        const { rateProviders } = this.providersService.userProviders;

        return {
            name: 'providers',
            label: this.$tc('titles.provider'),
            value: choosenProvider,
            options: this.providersService.toItemsList(rateProviders),
            disableOnEdit: false,
        };
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '6',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }

    beforeSend(form: IForm) {
        const newForm = structuredClone(form);
        newForm.filters.meal_type = [newForm.filters.meal_type as string];
        newForm.filters.room_type = [newForm.filters.room_type as string];

        return newForm;
    }
}
